import cn from "classnames";
import { useInView } from "framer-motion";
import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";
import { useRef } from "react";

import SectionTitle from "../../molecules/section-title/SectionTitle";
import useVisibilityTracker from "../../utils/hooks/useVisibilityTracker";

const Reimbursement = ({ data: { title, subtitle }, adoptionClassName }) => {
  const { t } = useTranslation();
  const reimbursementItems = [1, 2, 3, 4, 5, 6].map((item) => {
    return {
      title: `landing.reimbursement.${item}.title`,
      cost: `landing.reimbursement.${item}.cost`,
    };
  });

  const { ref } = useVisibilityTracker("reimbursement", {
    threshold: 0.5,
  });

  const figRef = useRef(null);

  const isFigVisible = useInView(figRef, {
    amount: 0.5,
    once: true,
  });

  return (
    <section ref={ref} className={cn(adoptionClassName, "or-reimbursement")}>
      <SectionTitle title={parse(title)} subtitle={subtitle} />
      <figure>
        <figcaption>{t("landing.reimbursement.image.title")}</figcaption>
        <dl>
          {reimbursementItems.map((item, i) => {
            return (
              <div
                ref={figRef}
                key={i}
                className={`reimbursement-item ${
                  isFigVisible ? "reimbursement-item-animation" : ""
                } reimbursement-item-not-visited`}
              >
                <dt>{t(item.title)}</dt>
                <dd>{t(item.cost)}</dd>
              </div>
            );
          })}
        </dl>
      </figure>
    </section>
  );
};

export const query = graphql`
  fragment ReimbursementFragment on ContentfulReimbursementSection {
    title
    subtitle
  }
`;

export default Reimbursement;
